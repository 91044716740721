import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/4.jpg';
import p5 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/5.jpg';
import p6 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/6.jpg';
import p7 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/7.jpg';
import p8 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/8.jpg';
import p9 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/9.jpg';
import p10 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/10.jpg';
import p11 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/11.jpg';
import p12 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/12.jpg';
import p13 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/13.jpg';
import p14 from 'assests/Photos/SchoolProgram/2023/Dussehra_celebration/14.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const Dussehra2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
          {
                    src: p1,
                    source: p1,
                    rows: 1,
                    cols: 2,
                },
       
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 2,
      },
    
      {
          src: p8,
          source: p8,
          rows: 2,
          cols: 1,
      }, 
      
      {
          src: p7,
          source: p7,
          rows: 2,
          cols: 1,
      },
       {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
      },
      {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
      },
      {
          src: p10,
          source: p10,
          rows: 1,
          cols: 1,
      },
      {
          src: p14,
          source: p14,
          rows: 1,
          cols: 1,
      },
      {
          src: p11,
          source: p11,
          rows: 1,
          cols: 2,
      },
      {
          src: p12,
          source: p12,
          rows: 1,
          cols: 2,
      },
      {
          src: p4,
          source: p4,
          rows: 1,
          cols: 2,
      },
     
      {
          src: p3,
          source: p3,
          rows: 1,
          cols: 2,
      }, 
      {
          src: p2,
          source: p2,
          rows: 1,
          cols: 2,
      },
      {
          src: p13,
          source: p13,
          rows: 1,
          cols: 1,
      },
    
     
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                DUSSEHRA CELEBRATION 	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: Pre-Primary   Date: 12 OCTOBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                We the Team of Pre-Primary are thrilled to share the delightful moments from our Dussehra Golu Celebration and the vibrant Dandiya Dance performance by our talented Pre-Primary students who showcased their enthusiasm and festive fervour with much excitement and joy.
                                    <br></br>
                                    The Dussehra Golu Celebration and Dandiya Dance by our Pre-Primary students was a spectacular event conducted on Thursday, 12 October 2023, that celebrated the victory of good over evil in a joyous and colourful manner. It reinforced the values of unity, tradition, and the importance of cultural awareness in the hearts of our young learners.
                                    <br></br>
                                 <strong>Golu Display </strong>   
                                    <br></br>
                                    The multi-purpose activity room of the Pre-Primary department was transformed into a mini universe of colours with the beautiful Golu display. This setup featured intricate arrangements of dolls, figurines and idols that depicted scenes from mythology, everyday life and various cultural themes. The attention to detail was astonishing. Each step narrated a unique story, adding a captivating dimension to the entire Golu.
                                    <br></br>
                                    <strong>Dandiya Dance Extravaganza </strong>   
                                    <br></br>
                                    The Dandiya dance performance was another highlight of the event. Our students adorned in vibrant traditional attire danced with pure joy and energy. The rhythmic beats and the colourful sticks added to the festive atmosphere.
                                    <br></br>
                                    With infectious smiles and foot-tapping music, our little ones filled the air with positivity and celebration. It was a testament to the spirit of togetherness and the belief that goodness and light will always prevail over darkness.
                                    <br></br>
                                    The excitement and joy on the faces of our students was truly heart warming. It was evident that they not only enjoyed the event but also imbibed the values and cultural significance of Dussehra.
                    
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                                fontFamily={' cursive'}
                            ><br />
                                “It’s time to commemorate the victory of good over evil. Let’s  walk ahead in life with the same spirit. Happy Dussehra 2023!”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Dussehra2023;